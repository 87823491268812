<template>
    <v-sheet>
      <Dialog :dialog="recordPaymentDialog" card-text-class="px-4 py-0" :dialogWidth="600">
        <template v-slot:title>Create Salary Month<v-spacer></v-spacer></template>
        <template v-slot:body>
          <v-container>
            
            <template>
              <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
                <v-row>
                  <v-col md="12" class="py-1">
                   
                    <v-autocomplete 
                  :items="months"
               
                  v-model="salary.salary_month"
 
                  outlined
                  :disabled="pageLoading"

                  hide-details
                  item-color="cyan"
                  placeholder="Select Month"
                  class="pt-0 mt-0"
                  item-text="name"
                  clearable
                  item-value="value"
                  :menu-props="{
                    contentClass: 'test-list',
                  }"
                >
                  <template v-slot:item="{ item }" class="custom_lists">
                    <div class="d-flex w-100 status-listing">
                      <v-list-item-content style="width:120px;">
                        <v-list-item-title >{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    
                    </div>
                    
                  </template>
                  
                </v-autocomplete>
                  </v-col>
              
                  
                
               
          
                </v-row>
              </v-layout>
            </template>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            :disabled="excelLoading"
            :loading="excelLoading"
            v-on:click="saveRecord()"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-2"
            :disabled="excelLoading"
            depressed
            tile
            v-on:click="$emit('close', false)"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </v-sheet>
  </template>
  <script>
  import Dialog from "@/view/components/Dialog";
  //import FileUpload from "@/view/components/FileUpload";
  import { formatMoney } from "accounting-js";
  import { CREATE_SALARY_MONTH } from "@/core/lib/customer.lib";

  import { GET_PAYMENT } from "@/core/lib/pos.lib";
  import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
  import moment from "moment-timezone";

  // import PurchaseDocument from "@/view/components/PurchaseDocument";
  export default {
    name: "item-dialog",
    title: "Dialog Item",
  
    data() {
      return {
        className: null,
        months: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
        excelLoading: null,
        recordPaymentDialog: false,
        paymentThroughList: [
          { text: "Petty Cash", value: 1 },
          { text: "Undeposited Founds", value: 2 },
        ],
        paymentModeList: [
          { text: "Cash", value: 1 },
          { text: "Credit Card Citi Bank", value: 2 },
          { text: "Credit Card C I M B", value: 3 },
          { text: "Credit Card Public Bank", value: 4 },
          { text: "Boss", value: 4 },
        ],
        salary:{
            salary_month:null,
            salary_year:Number(moment().format("Y")),

        },
        paymentRecordData: {
          payment_made: null,
          payment_number: null,
          payment_date: null,
          payment_mode: null,
          payment_through: null,
          reference: null,
          payment_notes: null,
          attachments: [],
        },
        paymentRecord: [
          {
            po_number: "PO0001",
            paymentDate: "25/10/2023",
            venderName: "business thrust techsoft pvt ltd",
            amount: 309.88,
          },
        ],
      };
    },
    mounted() {
      this.getPayment();
    },
    methods: {
      manageLimit(e) {
        if (
          this.paymentRecordData.payment_notes &&
          this.paymentRecordData.payment_notes.length > 249
        ) {
          e.preventDefault();
        }
      },
      onPaste(e) {
        let coppied = e.clipboardData.getData("Text");
        let preVal = this.paymentRecordData.payment_notes;
        let finalval = String(preVal) + String(coppied);
        if (Number(finalval.length) > 249) {
          let trimValue = finalval.substring(0, 250);
          this.paymentRecordData.payment_notes = trimValue;
          e.preventDefault();
        }
      },
      async getPayment() {
        const data = await GET_PAYMENT();
        this.paymentModeList1 = data;
        const paymentModeList2 = this.paymentModeList1.map((item) => {
          return {
            text: item.payment_type,
            value: item.value,
          };
        });
        this.paymentModeList = paymentModeList2;
      },
      formatMoney(money) {
        return formatMoney(money, {
          symbol: "RM",
          decimal: ".",
          thousand: ",",
          precision: 2,
          format: "%s%v",
        });
      },
      async saveRecord() {
        if (this.salary && !this.salary?.salary_month) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "The Month field is required" },
          ]);
          return false;
        }
  
        try {
          const customer = await CREATE_SALARY_MONTH(this.salary);
          this.$router.replace({
            name: "salary",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Salary Month  Created Successfully." },
          ]);
          this.recordPaymentDialog = false;
          this.$emit("success", "true");
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        


        }
      },
      getAmount() {
        if (this.pdetails.total) {
          this.records = [this.pdetails];
          const record1 = this.records.map((item) => {
            return {
              barcode: item.barcode,
              total: item.total,
              display_name: item.supplier?.display_name,
            };
          });
          this.record = record1;
        } else {
          this.records = this.bulkdata;
  
          const record1 = this.records.map((item) => {
            return {
              barcode: item.barcode,
              total: item.total,
              display_name: item.supplier?.display_name,
            };
          });
          this.record = record1;
        }
  
        this.paymentRecordData.payment_number = this.record.length;
      },
  
      getTotals() {
        this.product = this.record;
  
        let sum = 0;
  
        this.product?.forEach((element) => {
          sum = sum + Number(element.total);
        });
  
        this.paymentRecordData.payment = Number(sum);
        this.paymentRecordData.payment_made = Number(sum);
  
        return this.paymentRecordData.payment;
      },
    },
    watch: {
      dialog(params) {
        this.recordPaymentDialog = params;
      },
    },
    props: {
      dialog: {
        type: Boolean,
        required: true,
        default: false,
      },
      pdetails: {
        type: Object,
        default: () => {
          return [];
        },
      },
      bulkdata: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
  
    components: {
      Dialog,
      //FileUpload,
      // PurchaseDocument,
    },
  };
  </script>
  <style scoped>
  .table.table-sm td {
    padding: 0.6rem !important;
    vertical-align: middle;
  }
  .table.table-sm th {
    background-color: #ede8e8 !important;
  }
  </style>
  